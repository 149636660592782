import { useAsyncData, useRoute } from '#imports'
import { computed, ref, watch } from 'vue'

import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import type { DisplayedReview } from '../models/reviews'
import { transformReviewsResponse } from '../utils/transformReviewsResponse'

import { useGetOrderByParamValue } from './useGetOrderByParamValue'
import { useRequests } from './useRequests'

function fetchReviews(cursor = '') {
  const route = useRoute()
  const requests = useRequests()
  const locale = useI18nLocale()

  const defaultOrderBy = useGetOrderByParamValue()

  if (!requests || !('getReviews' in requests))
    return Promise.resolve({
      results: <DisplayedReview[]>[],
      next: '',
      previous: '',
      nextCursor: '',
      previousCursor: '',
    })

  return $httpFetch(requests.getReviews, {
    pathParams: route.params,
    queryParams: {
      ...insertIf(!!defaultOrderBy, { order_by: defaultOrderBy }),
      ...route.query,
      ...insertIf(!!cursor, { cursor }),
      translation_locale: locale,
    },
  }).then((response) => ({
    ...response,
    results: transformReviewsResponse(response.results),
  }))
}

function logError(error: Error) {
  const route = useRoute()
  const logger = useLogger()

  if (error instanceof Error) {
    logger.error(`[REVIEWS][${route.name?.toString()}]`, {
      error,
    })
  }
}

export function useReviewsList() {
  const route = useRoute()

  const cursor = ref()
  const isFetchMorePending = ref(false)
  const hasMoreReviews = ref(true)
  const moreReviews = ref<DisplayedReview[]>([])

  const {
    data,
    error: fetchReviewsError,
    pending,
    execute,
  } = useAsyncData(() => fetchReviews())

  const isInitialFetchPending = computed(() => pending.value)

  const canFetchMoreReviews = computed(() => {
    return !isEmpty(cursor.value)
  })

  const reviewsResponse = computed(() => {
    if (!isEmpty(cursor.value) && !isEmpty(moreReviews.value)) {
      return [...(data.value?.results ?? []), ...moreReviews.value]
    }

    return data.value?.results ?? []
  })

  watch(
    fetchReviewsError,
    () => {
      if (fetchReviewsError.value instanceof Error) {
        logError(fetchReviewsError.value)
      }
    },
    { immediate: true },
  )

  watch(
    data,
    (newData) => {
      cursor.value = newData?.nextCursor
    },
    { immediate: true },
  )

  watch(
    () => route.query,
    async () => {
      await execute()
      moreReviews.value = []
      hasMoreReviews.value = true
    },
  )

  async function fetchMoreReviews() {
    try {
      isFetchMorePending.value = true

      const { nextCursor, results } = await fetchReviews(cursor.value)

      cursor.value = nextCursor

      hasMoreReviews.value = !isEmpty(nextCursor)
      moreReviews.value = [...moreReviews.value, ...(results || [])]
    } catch (error) {
      if (error instanceof Error) {
        logError(error)
      }
    } finally {
      isFetchMorePending.value = false
    }
  }

  return {
    fetchMoreReviews,
    isInitialFetchPending,
    isFetchMorePending,
    reviewsResponse,
    canFetchMoreReviews,
  }
}

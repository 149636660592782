<template>
  <RevDivider v-if="reviews" class="my-24 md:my-32" />

  <RevCardCarousel
    v-if="reviews"
    desktop-card-width="narrow"
    mobile-card-width="narrow"
    :next-alternative-text="i18n(translations.alternativeNext)"
    :prev-alternative-text="i18n(translations.alternativePrevious)"
    size="extra-small"
    :title="i18n(translations.title)"
  >
    <div v-for="review in formattedReviews" :key="review.id" class="h-full">
      <UserCommentModal
        :displayProductLink="displayProductLink"
        :pictures="review.pictures"
        :review="review"
        :reviews="formattedReviews"
        :track-review-impression="true"
      >
        <template #trigger="{ open }">
          <RevCard
            class="h-full cursor-pointer hover:drop-shadow-xl"
            @click="open"
          >
            <div class="relative h-160">
              <RevIllustration
                alt=""
                class="rounded-lg h-full w-full object-cover"
                :height="160"
                :src="review.pictures[0]"
                :width="160"
              />

              <div
                class="rounded-lg absolute bottom-0 left-0 right-0 top-0"
                :class="styles.darkOverlay"
              />

              <RevTag
                class="absolute left-16 top-16"
                :label="`${review.customer.firstName} ${review.customer.lastName}`"
                variant="secondary"
              />

              <div
                class="mood-inverse absolute bottom-16 left-16 right-16 flex flex-col gap-16"
              >
                <RevRating :score="review.averageRate" size="extra-small" />
              </div>
            </div>
          </RevCard>
        </template>
      </UserCommentModal>
    </div>
  </RevCardCarousel>

  <RevDivider v-if="reviews" class="my-24 md:my-32" />
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'
import { computed, useCssModule } from 'vue'

import UserCommentModal from '@backmarket/nuxt-layer-reviews/review-display/UserCommentModal.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevCardCarousel } from '@ds/components/CardCarousel'
import { RevDivider } from '@ds/components/Divider'
import { RevIllustration } from '@ds/components/Illustration'
import { RevRating } from '@ds/components/Rating'
import { RevTag } from '@ds/components/Tag'

import { PRODUCT } from '~/scopes/product/route-names'
import { ROUTES } from '~/scopes/reviews/route-names'

import type { DisplayedReviewWithAttachments } from '../../models/reviews'

import translations from './ReviewsPhotosCarousel.translations'

const route = useRoute()

const i18n = useI18n()
const styles = useCssModule()

const props = defineProps<{
  reviews: DisplayedReviewWithAttachments[]
}>()

const displayProductLink =
  route.name !== ROUTES.PAGES.PRODUCT && route.name !== PRODUCT.HOME

const formattedReviews = computed(() => {
  return props.reviews.map((review: DisplayedReviewWithAttachments) => ({
    ...review,
    reviewDate: review.createdAt,
    purchaseDate: review.product.purchasedAt,
    pictures: review.attachments?.map(({ url }) => url) ?? [],
  }))
})
</script>

<style module>
.darkOverlay {
  background: linear-gradient(180deg, rgba(29, 32, 36, 0) 49.5%, #1d2024 80.5%);
}
</style>

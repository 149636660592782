<template>
  <UserCommentView
    :id
    :average-rate="averageRate"
    :comment
    :country-code="countryCode"
    :customer
    :displayProductLink="displayProductLink"
    :handled-with-care="handledWithCare"
    :is-highlighted="isHighlighted"
    :language-code="languageCode"
    :originalReview="shouldDisplayEditedReview ? originalReview : undefined"
    :product
    :purchase-date="product.purchasedAt"
    :review-date="createdAt"
    :track-review-impression="true"
    :trackingData="{
      onClick: clickTrackingData,
      onImpression: impressionTrackingData,
    }"
    :translated-comment="_translatedComment"
    @toggle-translation="onToggleTranslation"
  >
    <PhotosSlider
      v-if="isPhotosSliderEnabled"
      v-bind="props"
      :trackingData="clickTrackingData"
    />
  </UserCommentView>
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'
import { computed } from 'vue'

import UserCommentView from '@backmarket/nuxt-layer-reviews/review-display/UserCommentView.vue'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'

import { PRODUCT } from '~/scopes/product/route-names'

import { ROUTES } from '../../../route-names'
import type { DisplayedReview } from '../../models/reviews'
import PhotosSlider from '../PhotosSlider/PhotosSlider.vue'

import { useTranslateComment } from './useTranslateComment'

const props = withDefaults(
  defineProps<
    DisplayedReview & {
      position: number
      isHighlighted?: boolean
      showPhotos?: boolean
    }
  >(),
  { isHighlighted: false, showPhotos: true },
)

const route = useRoute()
const experiments = useExperiments()

const displayProductLink =
  route.name !== ROUTES.PAGES.PRODUCT && route.name !== PRODUCT.HOME

const { fetchTranslationIfMissing, translatedComment: _translatedComment } =
  useTranslateComment(props)

const isPhotosSliderEnabled = computed(() => {
  return (
    props.showPhotos &&
    (experiments['experiment.photosInReview'] ===
      'withPhotosDefaultRelevance' ||
      experiments['experiment.photosInReview'] === 'withPhotosNewRelevance')
  )
})

// COM-1517 - Clean AB test
const shouldDisplayEditedReview = computed(
  () =>
    props.handledWithCare &&
    experiments['experiment.displayEditedReviews'] !== 'withoutEditedReviews',
)

const impressionTrackingData = {
  reviewPosition: props.position,
  pageType: route.name as string,
  reviewPhotos: Boolean(props.attachments?.length),
  photosReviewsDisplayed: (props.attachments || []).length,
  zone: 'reviews_list',
}

const clickTrackingData = {
  zone: `review_position_${props.position}`,
  page_type: route.name as string,
}

async function onToggleTranslation() {
  await fetchTranslationIfMissing({ openToastOnError: true })
}
</script>
